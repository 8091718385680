<template>
  <vue-editor
    v-model="content"
    @text-change="readInput"
    :class="{
      'ligne-text': classComponent == 'ligne-text',
      'produit-description': classComponent == 'produit-description',
      objet:
        classComponent == 'objet' || classComponent == 'description-facture',
      'description-facture': classComponent == 'description-facture',
      disabledComponent: disabledComponent
    }"
  ></vue-editor>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    VueEditor
  },
  props: {
    value: {
      required: true
    },
    classComponent: {
      required: false
    },
    disabledComponent: {
      required: false
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    readInput() {
      this.$emit('changeText', this.content);
    }
  }
};
</script>
<style lang="scss">
.ql-file,
.ql-video,
.ql-image,
.ql-link {
  display: none !important;
}
.quillWrapper {
  margin: 8px 4px 4px 4px;
  &.disabledComponent {
    pointer-events: none;
    background-color: #e9ecef;
  }
  &.produit-description {
    width: 97%;
    .ql-editor.ql-blank::before {
      content: 'Description';
      font-style: normal;
      font-size: 15px;
    }
  }
  &.description-facture {
    .ql-editor.ql-blank::before {
      content: 'Description';
      font-style: normal;
      font-size: 15px;
    }
  }
  &.ligne-text {
    width: 100%;
    .ql-container.ql-snow {
      border: 1px solid #ccc;
      height: 50px;
    }
    .ql-editor.ql-blank::before {
      content: 'Entrez votre texte ici...';
      font-style: normal;
      font-size: 15px;
    }
  }
  &.objet {
    width: 99%;
    .ql-editor.ql-blank::before {
      content: 'Entrez votre texte ici...';
      font-style: normal;
      font-size: 15px;
    }
  }
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: auto !important;
  margin: 0;
  position: relative;
}
</style>
